// src/pages/AddClientTestimonial.js
import React, { useState } from 'react';
import { Container, Card, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import testimonialbg from './images/testimonial-background.jpg'

const AddTestimonial = () => {
  const [testimonial, setTestimonial] = useState({
    clientName: '',
    profession: '',
    testimonial: '',
    image: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTestimonial({
      ...testimonial,
      [name]: value
    });
  };

  const handleImageChange = (e) => {
    setTestimonial({
      ...testimonial,
      image: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('clientName', testimonial.clientName);
    formData.append('profession', testimonial.profession);
    formData.append('testimonial', testimonial.testimonial);
    if (testimonial.image) {
        formData.append('image', testimonial.image);
    }

    try {
      const response = await fetch('https://api.shreedigimarketing.in/api/testimonials', {
        method: 'POST',
        body: formData
    });
    

        const contentType = response.headers.get('content-type');
        if (response.ok) {
            if (contentType && contentType.includes('application/json')) {
                const result = await response.json();
                alert('Testimonial added successfully!');
                setTestimonial({ clientName: '', profession: '', testimonial: '', image: null });
            } else {
                const errorText = await response.text();
                console.error('Server returned non-JSON response:', errorText);
                alert('An unexpected response was received from the server.');
            }
        } else {
            const errorText = await response.text();
            console.error('Error response from server:', errorText);
            alert('Failed to add testimonial.');
        }
    } catch (error) {
        console.error('Caught an error:', error);
        alert('An unexpected error occurred. Please try again later.');
    }
};


  return (
    <Container className="my-5">
      <Card>
        <Card.Body>
          <h1>Add Client Testimonial</h1>
          <img src={testimonialbg} alt="Descriptive Text" className="img-fluid mb-4" style={{height:'70px', width:'200px'}}/>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formClientName">
              <Form.Label>Client Name:</Form.Label>
              <Form.Control
                type="text"
                name="clientName"
                value={testimonial.clientName}
                onChange={handleChange}
                placeholder="Enter client name"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formProfession">
              <Form.Label>Client Profession:</Form.Label>
              <Form.Control
                type="text"
                name="profession"
                value={testimonial.profession}
                onChange={handleChange}
                placeholder="Enter client profession"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTestimonial">
              <Form.Label>Testimonial:</Form.Label>
              <Form.Control
                as="textarea"
                name="testimonial"
                value={testimonial.testimonial}
                onChange={handleChange}
                placeholder="Enter client testimonial"
                rows={4}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formImage">
              <Form.Label>Upload Image:</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleImageChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Testimonial
            </Button>
            <Link to="/" className="ms-2">
              <Button variant="secondary">View Testimonials</Button>
            </Link>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddTestimonial;
