// src/pages/AddPlans.js
import React, { useState } from 'react';
import { Container, Card, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const AddPlan = () => {
  const [plan, setPlan] = useState({
    name: '',
    description: '',
    price: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlan({
      ...plan,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://api.shreedigimarketing.in/api/plans', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(plan)
      });
      if (response.ok) {
        alert('Plan added successfully!');
        setPlan({ name: '', description: '', price: '' });
      } else {
        alert('Failed to add plan.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container className="my-5">
      <Card>
        <Card.Body>
          <h1>Add New Plan</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formPlanName">
              <Form.Label>Plan Name:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={plan.name}
                onChange={handleChange}
                placeholder="Enter plan name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPlanDescription">
              <Form.Label>Description:</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={plan.description}
                onChange={handleChange}
                placeholder="Enter plan description"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPlanPrice">
              <Form.Label>Price:</Form.Label>
              <Form.Control
                type="text"
                name="price"
                value={plan.price}
                onChange={handleChange}
                placeholder="Enter plan price"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Plan
            </Button>
            <Link to="/">
                <Button variant="primary" >View Testimonials</Button>
              </Link>
          </Form>
        </Card.Body>
      </Card>
  
    </Container>
  );
};

export default AddPlan;
