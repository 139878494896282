import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AddPlan from './AddPlan';
import AddTestimonial from './AddTestimonial';
import AdminDashboard from './AdminDashboard';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path='/' element={<AdminDashboard/>}/>
          <Route path="/addplan" element={<AddPlan/>} />
          <Route path="/addtestimonial" element={<AddTestimonial/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
