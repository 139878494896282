// src/pages/AdminDashboard.js
import React from 'react';
import { Container, Button, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

const AdminDashboard = () => {
  return (
    <Container className="my-5">
      <h1 className="mb-4">Admin Dashboard</h1>
      <Row>
        <Col md={4} className="mb-3">
          <Card>
            <Card.Body>
              <Card.Title>Add Plans</Card.Title>
              <Card.Text>
                 add new plans to the system.
              </Card.Text>
              <Link to="/addplan">
                <Button variant="primary">Go to Add Plans</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-3">
          <Card>
            <Card.Body>
              <Card.Title>Add Client Testimonial</Card.Title>
              <Card.Text>
                Manage and add new client testimonials.
              </Card.Text>
              <Link to="/addtestimonial">
                <Button variant="primary">Go to Add Testimonial</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
